export const appHead = {"link":[{"rel":"preconnect","href":"https://a.storyblok.com"},{"rel":"dns-prefetch","href":"https://www.googletagmanager.com"},{"rel":"dns-prefetch","href":"https://leadbooster-chat.pipedrive.com"},{"rel":"icon","href":"/favicon.ico","sizes":"any"},{"rel":"icon","href":"/favicon-16x16.png","type":"image/png","sizes":"16x16"},{"rel":"icon","href":"/favicon-32x32.png","type":"image/png","sizes":"32x32"},{"rel":"icon","href":"/icon.png","type":"image/png","sizes":"512x512"},{"rel":"icon","href":"/renewabl_favicon-260x260.svg","type":"image/svg","sizes":"260x260"},{"rel":"apple-touch-icon","href":"/apple-touch-icon.png","type":"image/png","sizes":"180x180"}],"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1.0"},{"charset":"utf-8"},{"property":"og:image","content":"og-image.png"},{"property":"og:image:type","content":"image/png"},{"property":"og:image:width","content":"1200"},{"property":"og:image:height","content":"630"},{"name":"twitter:image","content":"og-image.png"},{"name":"twitter:image:type","content":"image/png"},{"name":"twitter:image:width","content":"1200"},{"name":"twitter:image:height","content":"630"},{"property":"og:type","content":"website"}],"style":[],"script":[{"src":"//cdn.callrail.com/companies/178401242/686f22f4fe7802f09f5e/12/swap.js","type":"text/javascript","defer":true,"body":true}],"noscript":[],"viewport":"width=device-width, initial-scale=1.0","titleTemplate":"%s %separator %siteName","htmlAttrs":{"lang":"en","class":"scroll-smooth"}}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const renderJsonPayloads = true

export const componentIslands = true

export const payloadExtraction = true

export const cookieStore = false

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink"}

export const asyncDataDefaults = {"deep":true}

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false